<script>
import { mapActions, mapGetters } from "vuex"

export default {
  name: "ShoppingCartService",
  data() {
    return {}
  },
  computed: {
    ...mapGetters("shoppingCart", {
      currentShoppingCart: "getCurrentCart",
    }),
  },
  methods: {
    ...mapActions("shoppingCart", {
      actionAddItem: "addItem",
      actionSubstractOne: "substractOne",
      addCustomerToCart: "setCustomer",
      resettaCarrello: "resetCart",
    }),
    addItem(item) {
      this.actionAddItem(item)
    },
    substractOne(item) {
      this.actionSubstractOne(item)
    },
    setCustomer(item) {
      console.log("🚀 ~ file: ShoppingCart.vue:28 ~ setCustomer ~ item:", item)
      this.addCustomerToCart(item)
    },
    resetCart() {
      console.log("resetta carrello mixin")
      this.resettaCarrello()
    },
  },
}
</script>
